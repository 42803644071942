<template>
  <div class="recome">
    <Else></Else>
    <div class="recome-box">
      <div class="recome-middle">
        <div class="recome-name">
          <div class="recome-name-picktype">
            <div class="recome-name-picktype-detail">
              <div class="recome-brand">
                <span>商标名称</span>
                <input type="text" placeholder="请输入商标名称" v-model="keyword" @blur="findBrand">
                <!-- <span class="brand-span line" @click="clickHandle()">线上申请</span>
                <span class="brand-span" @click="clickHandleOffline">线下申请</span> -->
              </div>
              <div class="recome-guild">
                <div>
                  <span>主营产品</span>
                </div>
                <div class="recome-guild-pickguild">
                  <div class="recome-guild-pickguild-div" v-for="(v,k) in jobArr" :key="k">
                      <input type="text" placeholder="请输入你的产品或服务名称"  v-model="jobArr[k]" @input="getNew(k)" @blur="newlose"><span v-if="k==0" @click="addjob">+</span>
                      <span  @click="deljob(k)" v-else>-</span>
                      <div class="recome-guild-pickguild-example"> 比如：机器人、蛋糕、饭店、酒店、金融、教育 </div>
                      <div class="recome-guild-select" v-if="newIs&&newIndex == k">
                          <div class="recome-guild-select-div" :title="item" v-for="(item,index) in newData" :key="index" @click="addOne(item,k)" v-show="newData.length !== 0">
                            {{item}}
                          </div>
                          <div class="recome-guild-select-div2" v-show="newData.length == 0">暂无匹配关键词</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div style="clear:both; margin-bottom: 40px;"></div>
              <div class="recome-next1" @click="namenext">AI评估</div>
            </div>
          </div>
        </div>
        <div class="recome-recomed" v-show="recomedshow">
          <div class="recomed2">
            <div class="recomed1">
              <!-- <div>杭州高新区（滨江）云上品牌指导站为您推荐</div> -->
              <div>星级与下证率说明：下证率根据国家商标局审查标准预测，商标星级越高，则注册成功率越高。</div>
              <div> </div>
            </div>
            <div class="recomed2-bigdiv">
              <div class="recomed2-bigdiv-left">
                <div class="recomed2-bigdiv-left-search">
                  <input type="text" placeholder="请输入产品或服务名称" v-model="searchkeyword" @keyup.enter="regsearch">
                  <img :src="require('/static/images/orderSearch.png')" alt="">
                </div>
                <div class="recomed2-bigdiv-left-three-link">
                  <div class="three-link" v-for="(v,k) in threelink" :key="k">
                    <div class="link-div1" @click="stretch1(v.categoryCode)">
                      <i :class="secondshow==isTags(v.categoryCode)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                      第{{ v.categoryCode }}类 {{ v.categoryName }}
                    </div>
                    <div class="link-div2" v-for="(item,index) in v.categorysList" :key="index" v-show="isTags(v.categoryCode)">
                      <div @click="stretch2(item.categoryCode)"><i
                        :class="thirdshow==isTags2(item.categoryCode)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                        {{ item.categoryCode }}{{ item.categoryName }}
                      </div>
                      <div class="link-div3" v-show="isTags2(item.categoryCode)" v-for="(m,n) in item.categorysList" :key="n"
                           @click="addcate(v.categoryCode,v.categoryName,item.categoryCode,item.categoryName,item.score,m.code,m.categoryName,item.rate)">
                        <span :class="{setActive: isexit(m.code)}"> {{ m.categoryName }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="recomed2-bigdiv-right">
                <div class="recomed2-bigdiv-right-top">
                  <div>
                    <span>主要类别推荐（{{mianNum}}）</span>
                    <span>相关类别推荐（{{ciNum}}）</span>
                  </div>

                </div>
                <div class="recomed2-bigdiv-right-showtype" ref="content">
                  <div class="recomed2-bigdiv-right-showtype-cirl" v-for="(item,index) in suggest" :key="index">
                    <div class="div1">
                      <!-- <input type="checkbox" @click="selectfirst(item.isSelect1,index)" v-bind:checked="item.isSelect1"> -->
                      <span>第{{ item.num }}类 {{ item.name }}</span>
                      <i class="el-icon-delete" @click="delfirst(index)"></i>
                      <span v-if="item.primary == 1">主要推荐类别</span>
                      <span v-if="item.primary == 0">相关推荐类别</span>

                      <span class="div1-span1" v-if="item.score != 0">下证率</span>
                      <span class="div1-span2" v-if="item.score != 0">{{ item.rate }}%</span>
                      <div v-if="item.score != 0">
                        <el-rate
                          v-model="item.score"
                          disabled
                          score-template="{value}">
                        </el-rate>
                      </div>

                    </div>
                    <div class="container" v-for="(v,k) in item.sec" :key="k">
                      <div class="div2">
                        <div>
                          <!-- <input type="checkbox" v-bind:checked="v.isSelect2"
                                 @click="selectsecond(v.isSelect2,index,k)"> -->
                          <div :title="v.name">{{ v.num }} {{ v.name }}</div>
                          <i class="el-icon-delete second" @click="delsecond(index,k)"></i>
                        </div>
                        <div v-if="v.score != 0">
                          <span class="div1-span1">下证率</span>
                          <span class="div1-span2">{{ v.randomNum }}%</span>
                          <el-rate
                            v-model="v.score"
                            disabled
                            score-template="{value}">
                          </el-rate>
                          <div v-if="v.score<=3" @click="like(item.num,v.num)">查看近似商标</div>
                        </div>
                      </div>
                      <div class="div3">
                        <div v-for="(m,n) in v.third" :class="{includeClass:isinclude(m.name)}" :key="n" >
                          <div :class="{includeClass:isinclude(m.name)}">{{ m.name }}</div>
                          <div :class="{includeClass:isinclude(m.name)}" @click="del(item.num,v.num,index,n,m.code,k)">x</div>
                        </div>
                        <div id="clear"></div>
                        <!-- <div class="add" @click="zu(item.num,v.num)">添加</div> -->
                      </div>
                      <div style="clear:both;"></div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table" v-show="maskshow">
      <el-table
        v-loading="loading" class="table">
      </el-table>
    </div>
  </div>
</template>

<script>
import Else from '../ui/else.vue';
const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export default {
  name: 'recome',
  data() {
    return {
      loading: true,
      maskshow: false,
      // 总的商标名称
      keyword: '',
     
      
      // 输入的行业名称
      // 小行业
      key: '',
      jobArr: [''],

      
     
      recomedshow: false,

      //右边推荐的数据
      suggest: [],
      // 左边第一大类的数据
      threelink: [],
      num: 0,
      // 全选的属性
      allcheck: false,

      //  保存所有三级id判断左边三级字体变红
      array: [],
      // 左边45大类的搜索关键词
      searchkeyword: '',
      //   搜索出来的数据数组
      searcharr: [],
      //    保存搜索出来所有一级和二级的id判断二级和三级的显示隐藏
      firstid: [],
      secondid: [],
      //    判断箭头是否向下
      secondshow: true,
      thirdshow: true,

      type: '',
      //   删除提示
      dialogVisible: false,

      // 保存获取回来的推荐的code来获取全部的星级
      code: '',
      // 存储星级
      star: [],
      radom: {},
      
      
      // 输入商品名称查询
      newIndex:'',
      newIs:false,
      newOne:'',
      newData:[],
      // 自己增加的小商品改变样式
      selfAdd:[],
      // 判断是否和行业一样
      inclu:[],
      mianNum:0,
      ciNum:0
    }
  },
  components: {
      Else
  },
  computed: {
    
  },
  watch: {
    'newOne'(newValue) {
      // console.log(this.jobArr)
      if (newValue == "") {
        this.newIs = false;
      } else {
        this.newIs = true;
        // console.log(newValue)
        for (var i = 0; i < this.newData.length; i++) {
          if (this.newData[i] == newValue) {
            this.newIs = false;
          }
        }
        delay(() => {
          this.fetchData();
        }, 400);
      }
    }
  },
  methods: {
    tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 3000
      })
    },
    handleScroll(){
      setTimeout(function(){
          $('html, body').animate({scrollTop: 1200}, 1000)
        },200)
      },
    async fetchData(val) {
      this.$http.post(this.GLOBAL.new_url + '/trademark/service/es/trademark/regiest/search/completion/suggest', {
         keyword: this.jobArr[this.newIndex],
         page:1,
         size:100
      }, {
        headers: {
          AuthToken: this.$cookie.getCookie('token'),
          'X-Request-Origin': 'xcx'
        }
      }).then(res => {
        // console.log(res);
        if (res.data.code == "200") {
          this.newData = res.data.data.wvNameList;
        }
      })
    },
    // 判断输入的商标名称是否合法
    findBrand(){
       let reg = /^[0-9]*$/;
      let reg2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
       if (this.keyword == '') {
          this.tip('error', "请输入商标名称")
          return false
        } else if (reg.test(this.keyword)) {
          this.tip('error', "请输入汉字或字母")
          return false
        } else if (reg2.test(this.keyword)) {
          this.tip('error', "请输入正确的商标名称(不包括特殊字符)")
          return false
        }else{
            this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/forbid', {
              text: this.keyword
            }).then(res => {
              // console.log(res)
              if (res.data.data.forbid == "True") {
                this.tip('error', res.data.data.reason);
                this.keyword = '';
              }
            })
        }
    },
    namenext() {
      if (this.keyword == '') {
          this.tip('error', "请输入商标名称")
          return false
        }else{
           this.key = this.jobArr.join(',')
          if (this.key.charAt(this.key.length - 1) == ',') {
            this.key = this.key.substring(0, this.key.length - 1);
          }
          if (this.key == '') {
            this.tip('error', '请输入您的产品名称或者服务行业')
            return false;
          } else{
            this.getdata1();
            this.handleScroll()
          }
        }
      
    },
    clickHandleOffline() {
                window.open('http://www.hhtzipr.net/platform')
            },
             clickHandle() {
                window.open('http://zscqyjs.zjamr.zj.gov.cn')
            },
    addjob() {
      if (this.jobArr.length != 5) {
        this.jobArr.push('');
      }
    },
    deljob(val) {
      this.jobArr.splice(val, 1)
    },
    getNew(k){
        this.newIndex = k;
        this.newOne = this.jobArr[k]
    },
    newlose(){
      setTimeout(() => {
        this.newIs = false;
      }, 500);
    },
    addOne(val,k){
      this.jobArr[k] = val;
      this.newlose()
    },
    //   获取检索推荐的数据
    getdata1() {
        this.suggest = [];
        this.array = [];
        this.inclu = [];
        var all_class;
         all_class = 0;
        this.maskshow = true;
        this.mianNum = 0;
        this.ciNum = 0;
        let province = localStorage.getItem('province')
        let city =  localStorage.getItem('city')
         let county =  localStorage.getItem('county')
          let town =   localStorage.getItem('town')
        this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/ai/getTrademarkRecommendData', {
          text: this.key,
          brandName: this.keyword,
          allClass: all_class,
          size: 10000,
          index: 1,
          province,
          city,
          county,
          town,
          userCode:this.$cookie.getCookie('u_id')
        }).then(res => {
          console.log(res);
          if (res.data.code == "200") {
            if (res.data.data.one.length != 0) {
              this.suggest = res.data.data.one;
              this.recomedshow = true;
              var _this = this;
              // 给推荐的一级数据里面添加属性
              this.suggest.map(function (item) {
                _this.$set(item, 'isSelect1', false);

              });
              this.getdata2();
              // 给推荐一级数据简洁的标题
              console.log(this.suggest)
              for (var i = 0; i < this.suggest.length; i++) {

                if(Number(this.suggest[i].num)<10){
                  this.suggest[i].num = "0"+Number(this.suggest[i].num)
                }
                if(this.suggest[i].primary == "1"){
                  this.mianNum += 1
                }
                if(this.suggest[i].primary == "0"){
                  this.ciNum += 1
                }
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  if(Number(this.suggest[i].sec[j].num)<1000){
                    this.suggest[i].sec[j].num = "0"+Number(this.suggest[i].sec[j].num)
                  }
                  _this.$set(this.suggest[i].sec[j], 'isSelect2', false)

                }
                
              }
              // 把所有三级的id保存在数组判断右边变红
              var allName = []
              for (var i = 0; i < this.suggest.length; i++) {
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  for (var m = 0; m < this.suggest[i].sec[j].third.length; m++) {
                    this.array.push(this.suggest[i].sec[j].third[m].code)
                    allName.push(this.suggest[i].sec[j].third[m].name)
                  }
                }
              }
              var jobName = this.key.split(",")
             
            for (let i = 0; i <jobName.length; i++) {
              for (let j = 0; j < allName.length; j++) {
                if(jobName[i]==allName[j]){
                  this.inclu.push(jobName[i]);
                }
            }
            }
  
              console.log(this.inclu)
              //子向父发送数据
      // this.$nextTick(()=>{
      //   var height =  document.documentElement.scrollHeight;
      //   console.log(height)
      //     window.parent.postMessage(
      //     {
      //       data: height,
      //     },
      //     "*"
      //   );
      // })
            } else {
              this.recomedshow = false;
              this.maskshow = false
              this.tip('error', '未检索到数据')
              //  this.suggest = res.data.data.data;
            }
          }
        }).catch((err) =>{
                this.maskshow = false
                console.log(err);
            });
      
    },
    getdata2() {
      this.$http.post(this.GLOBAL.new_url + '/goods/service/categorys/queryCategoryList', {
        categoryName: this.searchkeyword
      }).then(res => {
        if (res.data.code == '200') {
          // console.log(res)
          this.maskshow = false;
          this.threelink = res.data.data;
        
          if (this.keyword != '') {
            this.$http.post('https://aipro.guolaoai.com/newRate/rate', {
              text: this.keyword
            }).then(res => {
              console.log(res)
              if (res.data.code == '200') {
                for (var i in res.data.data) {
                  this.star.push({code: i, score: Number(res.data.data[i])})
                }
                this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/queryRandomIssuingRate', {}, {
                  headers: {
                    AuthToken: this.$cookie.getCookie('token'),
                    'X-Request-Origin': 'xcx'
                  }
                }).then(res => {
                  // console.log(res)
                  if (res.data.code == '200') {
                    // this.maskshow = false;
                    this.radom = res.data.data;
                    // console.log(this.radom)
                    for (var i = 0; i < this.threelink.length; i++) {
                      if (Number(this.threelink[i].categoryCode) < 10) {
                        this.threelink[i].categoryCode = '0' + this.threelink[i].categoryCode
                      }
                      for (var j = 0; j < this.threelink[i].categorysList.length; j++) {
                        for (var k = 0; k < this.star.length; k++) {
                          if (this.star[k].code == this.threelink[i].categorysList[j].categoryCode) {
                            this.threelink[i].categorysList[j].score = this.star[k].score
                            this.threelink[i].categorysList[j].rate = Number(this.radom[this.star[k].score])
                          }
                        }
                        if (Number(this.threelink[i].categorysList[j].categoryCode < 1000)) {
                          this.threelink[i].categorysList[j].categoryCode = '0' + this.threelink[i].categorysList[j].categoryCode;
                        }
                      }
                    }
                    for (var i = 0; i < this.suggest.length; i++) {
                      var star_i = [];
                      var rate_i = [];
                      for (var j = 0; j < this.suggest[i].sec.length; j++) {
                        for (var k = 0; k < this.star.length; k++) {
                          if (Number(this.star[k].code) == Number(this.suggest[i].sec[j].num)) {
                            this.suggest[i].sec[j].score = this.star[k].score
                            this.suggest[i].sec[j].randomNum = Number(this.radom[this.star[k].score])
                            star_i.push(this.suggest[i].sec[j].score);
                            rate_i.push(this.suggest[i].sec[j].randomNum);
                          }
                        }
                      }
                      this.suggest[i].score = Math.max.apply(null, star_i);
                      this.suggest[i].rate = Math.max.apply(null, rate_i)
                    }
                  }
                })
              }
            }).catch((err) =>{
                this.maskshow = false
                this.recomedshow = false;
                console.log(err);
            });
          } else {
            for (var i = 0; i < this.threelink.length; i++) {
              if (Number(this.threelink[i].categoryCode) < 10) {
                this.threelink[i].categoryCode = '0' + this.threelink[i].categoryCode
              }
              for (var j = 0; j < this.threelink[i].categorysList.length; j++) {
                if (Number(this.threelink[i].categorysList[j].categoryCode < 1000)) {
                  this.threelink[i].categorysList[j].categoryCode = '0' + this.threelink[i].categorysList[j].categoryCode;
                  this.threelink[i].categorysList[j].score = 0;
                  this.threelink[i].categorysList[j].rate = 0;
                }
              }
            }
            // this.maskshow = false
          }
        }
        // console.log(this.threelink)
      })
    },
    //   根据推荐所有的id的数组判断左边的三级是否变红
    isexit(val) {
      if (this.array.includes(String(val))) {
        return true;
      } else {
        return false;
      }
    },
    //   点击右边推荐三类的x删除数组里的数据和计算价格和选项
    // item.num,v.num,index,n,m.id,k
    del(val1, val2, val3, val4, val5, val6) {

        // 把三类的id从获取推荐所有三类id的数组中删除，左边相对应的三级不变红
      for(var i = this.array.length-1;i>=0;i--){
        if (val5 == this.array[i]) {
          this.$delete(this.array, i);
        }
      }
      //  console.log(this.array)
    for(var i = this.selfAdd.length-1;i>=0;i--){
        if (val5 == this.selfAdd[i]) {
          this.$delete(this.selfAdd, i);
        }
      }


      var star = [];
      var rate = [];
      for (var j = 0; j < this.suggest[val3].sec.length; j++) {
        if (val2 == this.suggest[val3].sec[j].num) {
          this.$delete(this.suggest[val3].sec[j].third, val4);

        }

        if (this.suggest[val3].sec[j].isSelect2 == true && this.suggest[val3].sec[j].third.length != 0) {
          star.push(this.suggest[val3].sec[j].score)
          rate.push(this.suggest[val3].sec[j].randomNum)
        }
      }

      for(let i = this.suggest[val3].sec.length-1;i>=0;i--){
          if (this.suggest[val3].sec[i].third.length == 0) {
          this.suggest[val3].sec.splice(val6, 1)
        }
         if (this.suggest[val3].sec.length == 0) {
          this.suggest.splice(val3, 1)
        }
        }
        if(this.suggest.length!=0){
          if (this.suggest[val3].isSelect1 == false) {
            this.suggest[val3].score = 0;
            this.suggest[val3].rate = 0;
          } else {
            this.suggest[val3].score = Math.max.apply(null, star);
            this.suggest[val3].rate = Math.max.apply(null, rate);
          }
        }
      

  //  console.log(this.suggest)
      // 计算价格和多少项
      var arr_val3 = 0;
      if(this.suggest.length!=0){
        for (var i = 0; i < this.suggest[val3].sec.length; i++) {
        if (this.suggest[val3].sec[i].isSelect2 == true) {
          for (var j = 0; j < this.suggest[val3].sec[i].third.length; j++) {
            arr_val3 += 1
          }
        }
      }
      this.suggest[val3].chose = arr_val3;
      if (arr_val3 > 10) {
        this.suggest[val3].price = this.basePrice + (arr_val3 - 10) * this.littleprice;
      } else if (0 < arr_val3 && arr_val3 <= 10) {
        this.suggest[val3].price = this.basePrice;
      } else {
        this.suggest[val3].price = 0;
      }
      }
      // console.log(this.array)
      
    },
     isinclude(val){
      if (this.inclu.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    //   点击左边三级自动添加到右边推荐数组里面
    //v.categoryCode,v.categoryName,item.categoryCode,item.categoryName,item.score,m.code,m.categoryName,item.rate
    addcate(val1, val2, val3, val4, val5, val6, val7, val8) {
      
      // 判断新增的小类是否存在
      var Name = []
      for(var i =0;i<this.suggest.length;i++){
        for(var j =0;j<this.suggest[i].sec.length;j++){
          for(var m =0;m<this.suggest[i].sec[j].third.length;m++){
            Name.push(this.suggest[i].sec[j].third[m].name)
          }
        }
      }
      if(Name.indexOf(val7) != -1){
        this.tip('error','您已选择过该商品'+'“'+val7+'”')
      }
      // console.log(Name)
      if (this.suggest.length != 0) {
        for (var i = 0; i < this.array.length; i++) {
          if (val6 == this.array[i]) {
            return false
          }
        }
        var str1 = [];
        var str2 = []
        for (var i = 0; i < this.suggest.length; i++) {
          str1.push(this.suggest[i].num);
          for (var j = 0; j < this.suggest[i].sec.length; j++) {
            str2.push(this.suggest[i].sec[j].num);
          }
        }
          if (str1.indexOf(val1) != -1) {
            if (str2.indexOf(val3) != -1) {
              let objcate3 = {code: val6, name: val7}
              for (var i = 0; i < this.suggest.length; i++) {
                for (var j = 0; j < this.suggest[i].sec.length; j++) {
                  if (val3 == this.suggest[i].sec[j].num) {
                    this.suggest[i].sec[j].third.push(objcate3)
                    this.array.push(String(val6))
                    this.isexit(String(val6))
                  }
                }
              }
            } else {
              let objcate2 = {
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }

              for (var i = 0; i < this.suggest.length; i++) {
                if (val1 == this.suggest[i].num) {
                  this.suggest[i].sec.push(objcate2)
                  this.array.push(String(val6))
                  this.isexit(String(val6))
                  this.suggest[i].score = val5
                  this.suggest[i].rate = val8
                }
              }

            }
          } else {
            let objcate = {
              chose: 0, isSelect1: true, price: 0, num: val1, name: val2, score: val5, rate: val8,
              sec: [{
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }]
            };

            this.suggest.push(objcate)
            this.array.push(String(val6))
            this.isexit(String(val6))
            // this.$nextTick(() => {
            //     this.$refs.content.scrollTop = this.$refs.content.scrollHeight
            //   })
          }
        
      } else {
        if (this.$route.query.shop_id) {
          if (this.$route.query.oneCate == val1) {
            let objcate = {
              chose: 0, isSelect1: true, price: 0, num: val1, name: val2, score: val5, rate: val8,
              sec: [{
                isSelect2: true,
                name: val4,
                num: val3,
                score: val5,
                randomNum: val8,
                third: [{code: val6, name: val7}]
              }]
            };

            this.suggest.push(objcate)
            this.array.push(String(val6))
            this.isexit(String(val6))
            // this.$nextTick(() => {
            //     this.$refs.content.scrollTop = this.$refs.content.scrollHeight
            // })
          } else {
            this.tip('error', '请选择同大类的小商品')
            return false
          }
        } else {
          let objcate = {
            chose: 0, isSelect1: true, price: 0, num: val1, name: val2, score: val5, rate: val8,
            sec: [{
              isSelect2: true,
              name: val4,
              num: val3,
              score: val5,
              randomNum: val8,
              third: [{code: val6, name: val7}]
            }]
          };

          this.suggest.push(objcate)
          this.array.push(String(val6))
          this.isexit(String(val6))
        }

      }

    },
    //   点击大类垃圾桶
    delfirst(index) {
      var threenum = []
      for (var j = 0; j < this.suggest[index].sec.length; j++) {
        for (var m = 0; m < this.suggest[index].sec[j].third.length; m++) {
          threenum.push(this.suggest[index].sec[j].third[m].code)
        }
      }
      for (var i = 0; i < threenum.length; i++) {
        for (var j = 0; j < this.array.length; j++) {
          if (threenum[i] == this.array[j]) {
            this.array.splice(j, 1);
          }
        }
        for (var j = 0; j < this.selfAdd.length; j++) {
          if (threenum[i] == this.selfAdd[j]) {
            this.selfAdd.splice(j, 1);
          }
        }
      }
      this.suggest.splice(index, 1)
    },
    //   点击组别垃圾桶
    delsecond(index, k) {
      var threenum = []
      for (var m = 0; m < this.suggest[index].sec[k].third.length; m++) {
        threenum.push(this.suggest[index].sec[k].third[m].code)
      }
      for (var i = 0; i < threenum.length; i++) {
        for (var j = 0; j < this.array.length; j++) {
          if (threenum[i] == this.array[j]) {
            this.array.splice(j, 1);
          }
        }
        for (var j = 0; j < this.selfAdd.length; j++) {
          if (threenum[i] == this.selfAdd[j]) {
            this.selfAdd.splice(j, 1);
          }
        }
      }
      this.suggest[index].sec.splice(k, 1)
      //  计算价格和选项
      if (this.suggest[index].sec.length == 0) {
        this.suggest[index].chose = 0;
        this.suggest[index].price = 0;
        this.suggest.splice(index, 1)
      } else {
        //   实时检测改变服务类型的变化
        var star = [];
        var rate = [];
        var all = [];
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          all.push(this.suggest[index].sec[i].isSelect2);
          if (this.suggest[index].sec[i].isSelect2 == true) {
            star.push(this.suggest[index].sec[i].score)
            rate.push(this.suggest[index].sec[i].randomNum)

          }
        }
        this.suggest[index].score = Math.max.apply(null, star);
        this.suggest[index].rate = Math.max.apply(null, rate);
        if (all.indexOf(true) == -1) {
          this.suggest[index].score = 0;
          this.suggest[index].rate = 0;
        }
        // 判断选了多少项和计算价格
        var total = 0;
        for (var i = 0; i < this.suggest[index].sec.length; i++) {
          if (this.suggest[index].sec[i].isSelect2 == true) {
            total += this.suggest[index].sec[i].third.length;
          }
        }
        this.suggest[index].chose = total;
        if (total > 10) {
          this.suggest[index].price = this.basePrice + (total - 10) * this.littleprice;
        } else if (0 < total && total <= 10) {
          this.suggest[index].price = this.basePrice;
        } else {
          this.suggest[index].price = 0;
        }
      }
    },
    //   查看近似商标
    like(val1, val2) {
        let routeData = this.$router.resolve({
          path: '/like', query: {
            type: this.keyword, cate_id: val1,
            num: val2
          }
        });
       this.$router.push(routeData.href);
      
    },
    
    //  搜索45大类
    regsearch() {
      this.getdata2();
      if(this.searchkeyword != ''){
        for (var i = 0; i < this.threelink.length; i++) {
        this.firstid.push(this.threelink[i].categoryCode);
        for (var j = 0; j < this.threelink[i].categorysList.length; j++) {
          this.secondid.push(this.threelink[i].categorysList[j].categoryCode);
        }
      }
      }else{
        this.firstid = [];
        this.secondid = [];
      }
      
    },
    //   判断搜索数据的展开和隐藏（以下4个方法）
    stretch1(val) {
      if (this.firstid.includes(val)) {
        for (var i = 0; i < this.firstid.length; i++) {
          if (val == this.firstid[i]) {
            this.firstid.splice(i, 1)
          }
        }
      } else {
        this.firstid.push(val);
      }
    },
    stretch2(val) {
      if (this.secondid.includes(val)) {
        for (var i = 0; i < this.secondid.length; i++) {
          if (val == this.secondid[i]) {
            this.secondid.splice(i, 1)
          }
        }
      } else {
        this.secondid.push(val);
      }
    },
    isTags(val) {
      if (this.firstid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    isTags2(val) {
      if (this.secondid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
     init() {
        let brandName = this.getQueryVariable('brandName')
        let productName = this.getQueryVariable('productName')
        // let brandName = '伽利略'
        // let productName = '你好'
        if (brandName && productName) {//初始化获取参数执行搜索
            this.keyword = decodeURI(brandName)
            this.$http.post(this.GLOBAL.new_url + '/trademark/service/ai/forbid', {
              text: this.keyword
            }).then(res => {
              // console.log(res)
              if (res.data.data.forbid == "True") {
                this.tip('error', res.data.data.reason);
                this.jobArr.push(decodeURI(productName))
            // console.log(this.jobArr)
                  this.jobArr.splice(0,1)
                return false
              }else{
                  this.jobArr.push(decodeURI(productName))
            // console.log(this.jobArr)
                  this.jobArr.splice(0,1)
                  this.namenext();
                  this.handleScroll();
              }
            })
            
        }
    },
    getQueryVariable(variable) {//获取路径参数
                let query = window.location.search.substring(1);
                let vars = query.split("&");
                for (let i = 0; i < vars.length; i++) {
                    let pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return (false);
            },
            
  },
  mounted() {
    this.init();
    window.parent.postMessage(
                  {
                    data: 1448,
                  },
                  "*"
                );
  },
  created() {

  },
  destroyed() {

  }
}

</script>

<style scoped>
.recome-box {
  width: 100%;
  height: 100%;
  background: #FEFEFE;
  padding-top: 55px;
}
.recome-middle {
  width: 1200px;
  margin: 0 auto;
}
.recome-name {
  width: 1200px;
  margin-bottom: 45px;
}
.recome-name-picktype-detail{
  padding-left: 30px;
}
.recome-brand{
  margin-bottom: 30px;
}
.recome-brand>span{
  vertical-align: middle;
}
.brand-span{
  float: right;
  display: inline-block;
  width: 130px;
  font-size: 14px;
  height: 40px;
  background: #FE8526;
  border-radius: 5px;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  margin-right: 35px;
}

.recome-guild>div{
  float: left;
}
.recome-guild>div:nth-of-type(1){
  line-height: 36px;
}
.recome-guild-pickguild ,.recome-brand>input {
  margin-left: 40px;
}
.recome-guild-pickguild-example{
  font-size: 12px;
  margin-top: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}
.recome-guild-pickguild-div {
  width: 715px;
  height: 50px;
  margin: 0 auto 30px;
  position: relative;
}

.recome-guild-pickguild-div > input,.recome-brand>input {
  width: 350px;
  height: 36px;
  padding-left: 20px;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  outline: none;
  margin-right: 30px;
  vertical-align: middle;
  font-size: 14px;
}

.recome-guild-pickguild-div > span {
  display: inline-block;
  width: 60px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(85, 84, 91, 0.1);
  vertical-align: middle;
  font-size: 22px;
  text-align: center;
  line-height: 36px;
  color: #383842;
  cursor: pointer;
}
.recome-guild-select{
  width: 340px;
  height: 300px;
  padding: 0 15px;
  background: #FFFFFF;
  overflow-y: auto;
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.09);
  position: absolute;
  top:45px;
  z-index: 10;
}
.recome-guild-select::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recome-guild-select::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recome-guild-select::-webkit-scrollbar-track {
  opacity: 0;
}
.recome-guild-select-div{
  height:50px;
  border-bottom: 1px solid #D6D6D6;
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.recome-guild-select-div2{
  height: 100%;
  text-align: center;
  color: #FF5E00 ;
  font-size: 16px;
  line-height: 300px;
}

.recome-next1 {
  width: 110px;
  height: 40px;
  background: #FE8526;
  border-radius: 4px;
  line-height: 40px;
  cursor: pointer;
  margin-left: 400px;
  text-align: center;
  font-size: 16px;
  color: white;
}

.table {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: rgba(0, 0, 0, 0.1);
}
.recome-recomed {
  margin-bottom: 50px;
}
.recomed1{
  padding-left: 40px;
  margin-bottom: 20px;
  font-size: 16px;
}
.recomed1>div{
  font-size: 14px;
  margin: 10px 0 6px;
  color: #979797;
}
.recomed2 {
  width: 1200px;
  height: 1050px;
  padding: 20px 0 0;
}

.recomed2-bigdiv {
  width: 1120px;
  height: 868px;
  border: solid 1px #e1e1e1;
  margin: 0 auto;
}

.recomed2-bigdiv > div {
  float: left;
  height: 868px;
}

.recomed2-bigdiv-left {
  width: 317px;
  border-right: solid 1px #e1e1e1;
}

.recomed2-bigdiv-left-search {
  width: 270px;
  height: 36px;
  margin: 16px auto 23px;
  position: relative;
}

.recomed2-bigdiv-left-search > input {
  width: 240px;
  height: 34px;
  padding-left: 27px;
  border-radius: 4px;
  outline: none;
  border: solid 1px #e1e1e1;
}

.recomed2-bigdiv-left-search > img {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 11px;
  left: 9px;
}

.recomed2-bigdiv-left-three-link {
  width: 295x;
  height: 750px;
  margin-left: 20px;
  overflow-y: auto;
}

.recomed2-bigdiv-left-three-link::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recomed2-bigdiv-left-three-link::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recomed2-bigdiv-left-three-link::-webkit-scrollbar-track {
  opacity: 0;
}

.el-icon-caret-right, .el-icon-caret-bottom {
  color: #EB5E00;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 14px;
}

.link-div1 {
  margin-bottom: 13px;
  font-size: 16px;
  width: 285px;
  height: 25px;
  cursor: pointer;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-div2 {
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  width: 260px;
  cursor: pointer;
  color: #333333;
}

.link-div2 > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-div3 {
  width: 200px;
  margin-left: 60px;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #333333;
}

.recomed2-bigdiv-right {
  width: 802px;
}

.recomed2-bigdiv-right-top {
  width: 802px;
  height: 60px;
  border-bottom: solid 1px #e1e1e1;
  background-color: #fafafa;

}
.recomed2-bigdiv-right-top >div{
  height: 60px;
  line-height: 60px;
  margin-left: 30px;
}
.recomed2-bigdiv-right-showtype {
  height: 807px;
  overflow-y: auto;
  border-bottom: solid 1px #e1e1e1;
}

.recomed2-bigdiv-right-showtype::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.recomed2-bigdiv-right-showtype::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.recomed2-bigdiv-right-showtype::-webkit-scrollbar-track {
  opacity: 0;
}

.recomed2-bigdiv-right-showtype-cirl {
  border-bottom: dotted 1px #e1e1e1;
  padding: 24px 0 0 36px;
}

.div1 {
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}

.div1 > input {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.div1 > span {
  vertical-align: middle;
}

.div1 > span:nth-of-type(1) {
  display: inline-block;
  width: 138px;
}

.div1 > span:nth-of-type(2) {
  font-size: 12px;
  color: #999999;
  margin-left: 10px;
}

.div1-span1 {
  font-size: 12px;
  margin: 0 4px 0 10px;
}

.div1-span2 {
  font-size: 12px;
  color: #FF5E00;
}

.div1 > div {
  float: right;
  margin-right: 275px;
  margin-top: 3px;
}

.second {
  margin-bottom: 4px;
}

.container {
  margin-bottom: 18px;
}

.div2 {
  height: 22px;
  margin: 0 0 8px 16px;
}

.div2 > div {
  float: left;
}

.div2 > div:nth-of-type(1) > input {
  float: left;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 14px;
  cursor: pointer;
  margin-top: 3px;
}

.div2 > div:nth-of-type(1) > div {
  width: 220px;
  float: left;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.div2 > div:nth-of-type(2) {
  float: left;
  margin-left: 7px;
}

.div2 > div:nth-of-type(2) > span {
  float: left;
  margin-top: 2px;
}

.div2 > div:nth-of-type(2) > div:nth-of-type(1) {
  float: left;
  height: 19px;
  margin: 0 0 0 8px;
}

.div2 > div:nth-of-type(2) > div:nth-of-type(2) {
  float: left;
  width: 96px;
  height: 19px;
  border: 1px solid red;
  font-size: 14px;
  color: #EB5E00;
  text-align: center;
  line-height: 19px;
  border-radius: 4px;
  cursor: pointer;
  background: #fbefe9;
  margin-left: 185px;
}

.div3 {
  margin-left: 16px;
}

.div3 > div {
  float: left;
  min-height: 33px;
  padding-left: 8px;
  border: dotted 1px #d6d6d6;
  margin: 0 20px 8px 0;
  padding-right:30px;
  position:relative;
}

.div3 > div > div {
  float: left;
}


.div3 > div > div:nth-of-type(1) {
  min-height: 33px;
  text-align: left;
  line-height: 33px;
  font-size: 14px;
  font-weight: 300;
  padding-right: 8px;
  color: #333333;
  font-size: 14px;
  font-weight: 300;
}

.div3 > div > div:nth-of-type(2) {
  width: 35px;
  height:100%;
  display:flex;
  /* align-items: center; */
  justify-content: center;
  border-left: 1px dotted #d6d6d6;
  background-color: #f5f5f5;
  font-size: 20px;
  color: #d6d6d6;
  text-align: center;
  cursor: pointer;
  position:absolute;
  right:0;
  top:50%;
  transform: translateY(-50%);
}
#clear {
  width: 0px;
  height: 0px;
  clear: both;
  border: none;
  margin: 0;
}
.includeClass{
  border-color: #EB5E00!important;
  color: #EB5E00!important;
  background: #fbefe9!important;
}

.setActive {
  color: #EB5E00 !important;
}



</style>
<style>
  .detail-picksize>div>div>input{
      width: 500px;
      height: 36px!important;
      border: solid 1px #d4d4d4;
      font-size: 14px;
      color: #666666;
      vertical-align: middle;
  }
  .detail-picksize .el-select .el-input.is-focus .el-input__inner,.el-select .el-input__inner:focus{
      border-color: #C0C4CC;
  }
  .el-select-dropdown__item.selected{
       color: #EB5E00;
   }
   .el-icon-delete{
        cursor: pointer;
        margin-left: 10px;
        vertical-align: middle;
        line-height: normal;
    }
    .el-rate__icon{
        font-size: 14px!important;
    }
  
</style>

